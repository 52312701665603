import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './ToolsPage.css'; // Import the CSS for styling
import ChatGPT from '../../component/chatgpt';
import tickIcon from '../../assets/tick-icon.png';

const tools = [
  {
    name: 'Pro Auditor',
    path: '/chatgpt',
    special: true,
    features: [
      "Automated Report Drafting",
      "Financial Data Interpretation",
      "Error Identification",
      "Audit Procedure Guidance",
      "Compliance Assistance",
      "Client Communication",
      "Query Handling",
      "Efficiency in Data Review",
      "Legal Drafting Assistance",
      "Case Research",
      "Audit Queries",
      "Complex Problem Solving"
    ]
  },
  {
    name: 'Pro Advocate',
    path: '/chatgpt',
    special: true,
    features: [
      "AI-Powered Documents",
      "Case Laws & Citations",
      "Image AI",
      "Extended Word Count",
      "Legal Drafting Assistance",
      "Case Research",
      "Document Review",
      "Client Communication",
      "Complex Legal Problem Solving",
      "Legal Queries Handling"
    ]
  },

  { name: 'Official Websites', icon: require('../../assets/tools/1.png'), path: '/Official-Websites' },
  { name: 'Merge PDF', icon: require('../../assets/tools/1.png'), path: '/merge-pdf' },
  { name: 'Split PDF', icon: require('../../assets/tools/2.png'), path: '/split-pdf' },
  { name: 'Compress PDF', icon: require('../../assets/tools/3.png'), path: '/compress-pdf' },
  { name: 'Word To PDF', icon: require('../../assets/tools/4.png'), path: '/word-pdf' },
  { name: 'PDF OCR', icon: require('../../assets/tools/5.png'), path: '/pdf-ocr' },
  { name: 'Page Number', icon: require('../../assets/tools/6.png'), path: '/page-number' },
  { name: 'PDF to JPG', icon: require('../../assets/tools/7.png'), path: '/pdf-jpg' },
  { name: 'JPG to PDF', icon: require('../../assets/tools/9.png'), path: '/img-pdf' },
  { name: 'Unlock PDF', icon: require('../../assets/tools/10.png'), path: '/unlock-pdf' },
  { name: 'Watermark PDF', icon: require('../../assets/tools/11.png'), path: '/watermark-pdf' },
  { name: 'Office to PDF', icon: require('../../assets/tools/8.png'), path: '/office-pdf' },
  // { name: 'Repair PDF', icon: require('../../assets/tools/13.png'), path: '/repair-pdf' },
  // { name: 'Rotate PDF', icon: rotatePdfIcon, path: '/rotate-pdf' },
  { name: 'Protect PDF', icon: require('../../assets/tools/14.png'), path: '/protect-pdf' },
  { name: 'PDF to PDFA-2B', icon: require('../../assets/tools/15.png'), path: '/pdf-pdfa' },
  { name: 'Validate PDF/A', icon: require('../../assets/tools/16.png'), path: '/validate-pdfa' },
  // { name: 'Extract PDF Text', icon: require('../../assets/tools/17.png'), path: '/extract-pdf' },
  { name: 'Resize Image', icon: require('../../assets/tools/18.png'), path: '/resize-image' },
  { name: 'Crop Image', icon: require('../../assets/tools/19.png'), path: '/crop-image' },
  { name: 'Compress Image', icon: require('../../assets/tools/20.png'), path: '/compress-image' },
  { name: 'Upscale Image', icon: require('../../assets/tools/21.png'), path: '/upscale-image' },
  { name: 'Remove Background', icon: require('../../assets/tools/22.png'), path: '/remove-background' },
  { name: 'Convert Image', icon: require('../../assets/tools/25.png'), path: '/convert-image' },
  { name: 'Watermark Image', icon: require('../../assets/tools/23.png'), path: '/watermark-image' },
  // { name: 'Rotate Image', icon: rotateImageIcon, path: '/rotate-image' },
  { name: 'Translate Pdf', icon: require('../../assets/tools/24.png'), path: '/translate-pdf' },
  // { name: 'Speech To Text', icon: watermarkImageIcon, path: '/speech-to-text' },
  { name: 'Speech To Text', icon: require('../../assets/tools/26.png'), path: '/speech-to-text-live' },

  
];

const ToolsPage = () => {
  const [showChatGPT, setShowChatGPT] = useState(false);
  const navigate = useNavigate(); // Hook to handle navigation

  // Function to handle tool clicks
  const handleToolClick = (path, isSpecial) => {
    if (isSpecial) {
      setShowChatGPT(true); // Show ChatGPT for special tools
    } else {
      navigate(path); // Navigate to respective tool page for normal tools
    }
  };

  // If ChatGPT should be shown, render it
  if (showChatGPT) {
    return <ChatGPT />;
  }

  // Render the tool selection page
  return (
    <div className="tools-page">
      <h1>Every tool you need to work with PDFs and Images</h1>
      <p>Select a tool to get started:</p>
      <div className="tools-container">
        <div className="feature-tools-container">
          {tools.filter(tool => tool.special).map((tool, index) => (
            <div key={index} className="feature-tool-card" onClick={() => handleToolClick(tool.path, true)}>
              <h2>{tool.name}</h2>
              <ul>
                {tool.features.map((feature, idx) => (
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                  <img src={tickIcon} alt={`Tick Icon`} style={{width: 15, height: 15, backgroundSize: 'contain'}} />
                  <li key={idx}>{feature}</li>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="tools-grid">
          {tools.filter(tool => !tool.special).map((tool, index) => (
            <div key={index} className="tool-card" onClick={() => handleToolClick(tool.path, false)}>
              <img src={tool.icon} alt={`${tool.name} Icon`} className="tool-icon" />
              <h2>{tool.name}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolsPage;
